import "./styles.css";

import PartySocket from "partysocket";

declare const PARTYKIT_HOST: string;

let pingInterval: ReturnType<typeof setInterval>;

// Let's append all the messages we get into this DOM element
const output = document.getElementById("app") as HTMLDivElement;
const connected = document.createElement("div");
connected.innerText = "Connecting...";
output.appendChild(connected);

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: "my-new-room",
});

// You can even start sending messages before the connection is open!
conn.addEventListener("message", (event) => {
  createOrUpdateCursor(event.data);
});

type CursorNodes = Record<string, HTMLDivElement>;
const cursorNodes: CursorNodes = {};

function createOrUpdateCursor(data: string) {
  const [id, coord] = data.split(":");
  if (!coord) return;
  const [x, y] = coord.split(",").map((n) => Number(n));
  if (!cursorNodes[id]) {
    cursorNodes[id] = create(id, x, y);
    output.appendChild(cursorNodes[id]);
  } else {
    update(cursorNodes, id, x, y);
  }
}

function create(id: string, x: number, y: number): HTMLDivElement {
  const element = document.createElement("div");
  element.style.position = "absolute";
  element.style.top = `${y}px`;
  element.style.left = `${x}px`;

  element.append(document.createTextNode("👋"));
  return element;
}

function update(
  cursorNodes: CursorNodes,
  id: string,
  x: number,
  y: number
): HTMLDivElement {
  const element = cursorNodes[id];
  element.style.top = `${y}px`;
  element.style.left = `${x}px`;
  element.innerHTML = document.createTextNode("🔥").textContent || "";
  return element;
}

function throttle<T extends (...args: any[]) => any>(
  fn: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | undefined;
  return (...args: Parameters<T>) => {
    if (timeout) return;
    timeout = setTimeout(() => {
      fn(...args);
      timeout = undefined;
    }, wait);
  };
}

const throttleSendEvent = throttle((event: MouseEvent) => {
  conn.send(event.x + ", " + event.y);
}, 100);

addEventListener("mousemove", (event) => {
  throttleSendEvent(event);
});

conn.addEventListener("open", () => {
  connected.innerText = "Connected!";
});
